import {
  forwardRef,
  type ComponentPropsWithRef,
  type ElementType,
  type ForwardedRef,
} from "react";

import { cn } from "@intergamma/common/cn";

// Taken from https://www.totaltypescript.com/pass-component-as-prop-react
type FixedForwardRef = <T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
) => (props: P & React.RefAttributes<T>) => React.ReactNode;

const fixedForwardRef = forwardRef as FixedForwardRef;

type DistributiveOmit<T, TOmitted extends PropertyKey> = T extends any
  ? Omit<T, TOmitted>
  : never;

const UnwrappedButton = <TAs extends ElementType>(
  props: DistributiveOmit<
    ComponentPropsWithRef<ElementType extends TAs ? "button" : TAs>,
    "as"
  > & {
    as?: TAs;
    disabled?: boolean;
    variant?: "primary" | "secondary" | "conversion";
    loading?: boolean;
    size?: "default" | "small";
  },
  ref: ForwardedRef<any>,
) => {
  const {
    as,
    children,
    disabled,
    variant = "primary",
    loading = false,
    size = "default",
    className,
    ...rest
  } = props;

  const Comp = disabled || loading || !as ? "button" : as;

  return (
    <Comp
      className={cn(
        "relative flex shrink-0 cursor-pointer items-center justify-center overflow-hidden font-primary transition-colors duration-100 gamma:rounded-md gamma:font-bold karwei:font-normal",
        "outline-none ring-ignew-functional-focus-400 ring-offset-1 focus-visible:ring-3",

        variant === "primary" &&
          // prettier-ignore
          cn(
            "bg-brand-primary text-white",
            !disabled && !loading && "gamma:hover:bg-ignew-functional-primary-400 karwei:hover:bg-ignew-functional-primary-700",
            !disabled && !loading && "gamma:active:bg-ignew-functional-primary-400 karwei:active:bg-ignew-functional-primary-700",
            disabled && "gamma:bg-ignew-neutral-300 karwei:bg-ignew-functional-primary-500",
          ),
        variant === "secondary" &&
          // prettier-ignore
          cn(
            "border border-brand-primary bg-white text-brand-primary",
            !disabled && !loading && "gamma:hover:border-ignew-functional-primary-400 gamma:hover:text-ignew-functional-primary-400",
            !disabled && !loading && "karwei:hover:border-ignew-functional-primary-700 karwei:hover:bg-ignew-neutral-50 karwei:hover:text-ignew-neutral-700",
            !disabled && !loading && "gamma:active:border-ignew-functional-primary-400 gamma:active:text-ignew-functional-primary-500",
            !disabled && !loading && "karwei:active:border-ignew-functional-primary-700 karwei:active:bg-ignew-neutral-50 karwei:active:text-ignew-neutral-700",
            disabled && "gamma:border-ignew-neutral-300 gamma:text-ignew-neutral-400",
            disabled && "karwei:border-ignew-functional-primary-500 karwei:bg-ignew-functional-primary-50 karwei:text-ignew-neutral-500",
          ),
        variant === "conversion" &&
          // prettier-ignore
          cn(
            "bg-brand-conversion text-white",
            !disabled && !loading && "hover:bg-ignew-functional-conversion-700 active:bg-ignew-functional-conversion-700",
            disabled && "gamma:bg-ignew-neutral-300 karwei:bg-ignew-functional-primary-500",
          ),

        size === "small" && "h-8 px-3 text-75/4",
        size === "default" && "h-12 px-4 text-base/4",

        (disabled || loading) && "cursor-auto",

        className,
      )}
      disabled={disabled || loading}
      {...rest}
      ref={ref}
    >
      <span>
        {loading && (
          <span
            className={cn(
              "absolute right-full top-1/2 h-[250px] w-[125%] -translate-y-1/2 gamma:rounded-full",
              "motion-safe:animate-button",
              variant === "primary" &&
                "gamma:bg-ignew-functional-primary-400 karwei:bg-ignew-functional-primary-700",
              variant === "conversion" && "bg-ignew-functional-conversion-700",
            )}
          />
        )}
        <span
          className={cn(
            "relative",
            loading &&
              "motion-safe:animate-button-text motion-reduce:opacity-50",
          )}
        >
          {children}
        </span>
      </span>
    </Comp>
  );
};

export const Button = fixedForwardRef(UnwrappedButton);
