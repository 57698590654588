import {
  useState,
  KeyboardEvent,
  ChangeEvent,
  ClipboardEvent,
  ReactNode,
  ComponentProps,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import { cn } from "@intergamma/common/cn";

import { Error } from "./Error";
import { Description } from "./Description";
import { Label } from "./Label";
import { inputStyles } from "./styles";

/**
 * The length of the verification code that the user needs to enter.
 */
export const CODE_LENGTH: 4 | 6 = 6;

export type CodeRef = {
  reset: () => void;
  focus: () => void;
};

type CodeFieldProps = {
  label?: string;
  info?: ComponentProps<typeof Label>["info"];
  description?: ReactNode;
  error?: ReactNode;
  disabled?: boolean;
  onChange: (value: string) => void;
};

export const CodeField = forwardRef<CodeRef, CodeFieldProps>(
  ({ label, description, error, info, disabled, onChange }, ref) => {
    const [code, setCode] = useState<string[]>(Array(CODE_LENGTH).fill(""));

    const focus = () => document.getElementById("code-0")?.focus();
    const reset = () => {
      setCode(Array(CODE_LENGTH).fill(""));
      focus();
    };

    useImperativeHandle(ref, () => ({
      reset,
      focus,
    }));

    const handleCodeInput = (
      e: KeyboardEvent<HTMLInputElement>,
      index: number,
    ) => {
      if (e.key === "Backspace") {
        document.getElementById(`code-${index - 1}`)?.focus();
      } else if (e.key.match(/^[a-z0-9]$/i)) {
        document.getElementById(`code-${index + 1}`)?.focus();
      }
    };

    const handleInputChange = (
      e: ChangeEvent<HTMLInputElement>,
      index: number,
    ) => {
      // Create shallow copy of the code array.
      const updatedCode = [...code];

      // Set the correct code based on the index of the input field.
      updatedCode[index] = e.target.value;
      setCode(updatedCode);
      onChange(updatedCode.join(""));
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      const { clipboardData } = e;
      const pastedData = clipboardData?.getData("Text");

      if (pastedData?.length === CODE_LENGTH) {
        // Split the code so we can set the value of each field.
        const codes = pastedData.split("");

        setCode(codes);

        onChange(pastedData);
      }
    };

    useEffect(() => {
      if (!disabled) {
        focus();
      }
    }, [disabled]);

    useEffect(() => {
      if (error) {
        reset();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const codeFields: JSX.Element[] = Array.from(
      { length: CODE_LENGTH },
      (_, index) => (
        <input
          key={index}
          type="text"
          id={`code-${index}`}
          name={`code-${index}`}
          data-testid={`code-${index}`}
          value={code[index]}
          maxLength={1}
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder="_"
          onKeyUp={(e) => handleCodeInput(e, index)}
          onChange={(e) => handleInputChange(e, index)}
          onPaste={handlePaste}
          onFocus={(e) => e.target.setSelectionRange(1, 1)}
          disabled={disabled}
          className={cn(
            inputStyles({ error, type: "text" }),
            "h-12 w-10 p-1 text-center text-300",
          )}
          {...(index === 0 && { autoComplete: "one-time-code" })}
        />
      ),
    );

    return (
      <div className="grid gap-1 text-center">
        {label && (
          <Label
            htmlFor="code-0"
            info={info}
            optional={false}
            className="w-full text-center font-bold"
          >
            {label}
          </Label>
        )}
        <div className="flex justify-center">
          <div className="flex w-full max-w-[308px] flex-row justify-between">
            <div className="flex w-full max-w-[142px] flex-row justify-end gap-2">
              {codeFields.slice(0, CODE_LENGTH / 2)}
            </div>
            <div className="flex w-full max-w-[142px] flex-row justify-start gap-2">
              {codeFields.slice(CODE_LENGTH / 2, CODE_LENGTH)}
            </div>
          </div>
        </div>
        {error && typeof error !== "boolean" && (
          <Error className="mt-1">{error}</Error>
        )}
        {!error && description && (
          <Description className="mt-1">{description}</Description>
        )}
      </div>
    );
  },
);
