import { cn } from "@intergamma/common/cn"
import { LoyaltyCard } from "@intergamma/loyalty-card"
import { PrivacyMask } from "@intergamma/privacy-mask"
import { Logout } from "@intergamma/ui/icons/Logout"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router"
import { ConfirmDialog } from "~/components/ConfirmDialog"
import { useSessionData } from "../session-data/SessionDataContext"
import { NavigationItem } from "./components/NavigationItem"
import { useLogout } from "./hooks/useLogout"
import { useNavigationItems } from "./hooks/useNavigationItems"

export function Navigation() {
  const { t, i18n } = useTranslation(["urls", "common", "dashboard", "logged-in-navigation"])
  const customer = useSessionData()
  const navigationItems = useNavigationItems()
  const { logout } = useLogout()
  const { pathname } = useLocation()
  const isOnDashboard = pathname === t("/myaccount") || `${pathname}/` === t("/myaccount")

  if (!customer.isLoggedIn) {
    return null
  }

  return (
    <nav className="flex w-full flex-col gap-6 md:max-w-[266px]" data-testid="myaccount-nav">
      <h1 className="hidden flex-col gap-1 px-4 text-600 font-bold leading-8 gamma:text-brand-primary-600 karwei:text-ignew-neutral-1000 md:flex">
        {customer.firstName ? (
          <>
            <span className="text-100 font-normal leading-6">{t("dashboard:heading").trim()},</span>
            <PrivacyMask>{customer.firstName}</PrivacyMask>
          </>
        ) : (
          t("dashboard:heading")
        )}
      </h1>
      {isOnDashboard && customer.defaultLoyaltyCardNumber && (
        <div className="mx-auto mt-4 flex w-full max-w-80 md:hidden" data-testid="loyalty-card">
          <Link to={t("urls:/profile/loyaltycards")}>
            <LoyaltyCard cardNumber={customer.defaultLoyaltyCardNumber} language={i18n.language} />
          </Link>
        </div>
      )}
      <ul className={cn(!isOnDashboard && "hidden md:block")}>
        {navigationItems.map((item) => {
          return item ? <NavigationItem key={item.title} {...item} /> : null
        })}
        <ConfirmDialog
          trigger={
            <NavigationItem
              key={t("logged-in-navigation:logout")}
              title={t("logged-in-navigation:logout")}
              Icon={Logout}
            />
          }
          title={t("logged-in-navigation:accountScreen.logoutTitle")}
          message={t("logged-in-navigation:accountScreen.logoutMessage")}
          action={logout}
          hasDarkPattern={false}
          buttonLabels={{
            confirm: t("logged-in-navigation:accountScreen.logoutPositive"),
            cancel: t("logged-in-navigation:accountScreen.logoutNegative"),
          }}
        />
      </ul>
    </nav>
  )
}
