import { memo, Suspense } from "react"

import { Container } from "@intergamma/container"
import { media } from "@intergamma/theme"

import { Outlet } from "react-router"
import { styled } from "styled-components"

import { Authenticated } from "~/features/session-data/Authenticated"

import { Navigation } from "~/features/navigation/Navigation"
import { LoadingPageLayout } from "./LoadingPageLayout"
import { StyledPage } from "./PageLayout"

function ProfileLayoutView() {
  return (
    <PageContainer>
      <Authenticated>
        <StyledContainer>
          <StyledInnerContainer>
            <Navigation />
            <Suspense fallback={<LoadingPageLayout />}>
              <Outlet />
            </Suspense>
          </StyledInnerContainer>
        </StyledContainer>
      </Authenticated>
    </PageContainer>
  )
}

export const ProfileLayout = memo(ProfileLayoutView)

const PageContainer = styled.div`
  background: white;

  ${StyledPage} {
    padding: 0 1rem;
    background: ${({ theme }) => theme.colors.white};
  }
`

const StyledContainer = styled(Container)`
  padding: 0;

  ${media.md`
    padding: 1.5rem 0;
  `}
`

const StyledInnerContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  ${media.md`
    flex-direction: row;
  `}
`
