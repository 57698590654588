function r(a, e) {
  if (a.length < 2)
    throw new Error(`Could not get allocation for ${a}`);
  const o = 1 / a.length * e * 100, n = 1 / a.length * (e + 1) * 100;
  return [o, n];
}
function t(a) {
  const e = {};
  if (!a.includes("A-control"))
    throw Error("No control group found with name 'A-control'");
  for (const [o, n] of a.entries())
    e[n] = {
      allocation: r(a, o)
    };
  return e;
}
const i = {
  // CHECKOUT ------------------------------------------------------------------
  "abtest_dba_3160_1-dba_4": {
    scope: "local",
    description: "Points redemption by default ON",
    variants: t(["A-control", "B-redemption"]),
    domain_and_index: "abtest_name_dba_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3009-dba_7": {
    scope: "local",
    description: "Updated cart tiles",
    variants: t(["A-control", "B-bin", "C-cross"]),
    domain_and_index: "abtest_name_dba_7",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3220-dba_3": {
    scope: "local",
    description: "Show free pickup or delivery in cart summary",
    variants: t(["A-control", "B-show"]),
    domain_and_index: "abtest_name_dba_3",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3172-dba_5": {
    scope: "local",
    description: "Better distinguish billing address and reshuffle input fields",
    variants: t(["A-control", "B-reshuffle"]),
    domain_and_index: "abtest_name_dba_5",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3029-dba_2": {
    scope: "local",
    description: "Show shipping address as part of the home delivery method form",
    variants: t(["A-control", "B-optout", "C-optin"]),
    domain_and_index: "abtest_name_dba_2",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  "abtest_dba_3138-dba_1": {
    scope: "local",
    description: "Payment method icon in Checkout CTA button",
    variants: t(["A-control", "B-text", "C-icon"]),
    domain_and_index: "abtest_name_dba_1",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  },
  // MYACCOUNT -----------------------------------------------------------------
  // SHOPFRONT -----------------------------------------------------------------
  "abtest_xtd_autosug-ew_4": {
    scope: "local",
    description: "Extend autosuggest/autocomplete with categories filter",
    variants: t(["A-control", "B-six-sug", "C-incl-cat"]),
    domain_and_index: "abtest_name_ew_4",
    formula: {
      gamma_nl: !0,
      gamma_be: !0,
      karwei_nl: !0
    }
  }
};
export {
  i as experiments
};
