import { jsxs as h, Fragment as ee, jsx as e } from "react/jsx-runtime";
import { createContext as Ge, useContext as Be, useMemo as re, useState as E, useCallback as W, useEffect as L, useId as Ye, useRef as ye } from "react";
import { useTranslation as C, Trans as ve } from "react-i18next";
import { dispatchAdobeEvent as S } from "@intergamma/adobe-tracking";
import { cn as q } from "@intergamma/common/cn";
import { useConfig as H } from "@intergamma/config";
import { Edit as pe, Correct as Se } from "@intergamma/icons";
import { PrivacyMask as K } from "@intergamma/privacy-mask";
import { Button as Q } from "@intergamma/ui/button";
import { calculatePasswordStrength as Le, useStrengthText as Pe, TextField as ce, PasswordField as Ne, CheckboxField as be, CODE_LENGTH as se, CodeField as Ve } from "@intergamma/ui/form";
import { ExclamationMark as Ce } from "@intergamma/ui/icons/ExclamationMark";
import { Snackbar as _e } from "@intergamma/ui/snackbar";
import { zodResolver as de } from "@hookform/resolvers/zod";
import { useForm as ue, Controller as fe } from "react-hook-form";
import { useSearchParams as je, useLocation as He, Navigate as ze, useNavigate as Ke } from "react-router";
import { l as me } from "./datadog-logging-Ds9IeYxC.js";
import { useQuery as Qe, useMutation as ge } from "@tanstack/react-query";
import { c as Je, a as Xe } from "./index-C2EW4nef.js";
import { datadogRum as te } from "@datadog/browser-rum";
import { isAxiosError as he } from "axios";
import { z as I } from "zod";
import Ze from "@intergamma/mailcheck";
import { isSyntheticTest as et } from "@intergamma/common/synthetic";
import { toast as we } from "react-toastify";
import { signIn as tt } from "aws-amplify/auth";
import { LoyaltyCard as rt } from "@intergamma/loyalty-card";
import { CheckMark as st } from "@intergamma/ui/icons/CheckMark";
import { getMobileOperatingSystem as nt, MobileOperatingSystem as ot } from "@intergamma/common/device";
var R = /* @__PURE__ */ ((t) => (t[t.LOGIN = 0] = "LOGIN", t[t.SIGN_UP = 1] = "SIGN_UP", t[t.VERIFY = 2] = "VERIFY", t[t.FORGOT_PASSWORD = 3] = "FORGOT_PASSWORD", t))(R || {}), Z = /* @__PURE__ */ ((t) => (t[t.LOGIN = 0] = "LOGIN", t[t.RESEND_VERIFICATION = 1] = "RESEND_VERIFICATION", t[t.RESEND_SUCCESS = 2] = "RESEND_SUCCESS", t))(Z || {}), xe = /* @__PURE__ */ ((t) => (t.CODE = "CODE", t.HASH = "HASH", t))(xe || {});
const it = {
  signUpRequestBody: {
    email: "",
    password: "",
    locale: "nl",
    optIn: !1,
    verificationMethod: "CODE",
    passwordless: !1
  },
  setSignUpRequestBody: () => {
  },
  setSignUpResponse: () => {
  },
  loginAfterSignUp: !1,
  setLoginAfterSignUp: () => {
  },
  setVerificationError: () => {
  },
  setFlow: () => {
  }
}, Oe = Ge(it), at = Oe.Provider, ie = () => Be(Oe), Ue = (t, s, r) => ({
  gamma: {
    nl: {
      loyaltyProgram: `${r}/voordeelpas`,
      requirementsLoyalty: `${r}/klantenservice/algemene-voorwaarden/voordeelpas`
    },
    "nl-BE": {
      loyaltyProgram: `${r}/nl/klanteninformatie/gammapluskaart`,
      requirementsLoyalty: `${r}/nl/klanteninformatie/gammapluskaart?hp-gammaplus`
    },
    "fr-BE": {
      loyaltyProgram: `${r}/fr/infos-consommateur/carte-gammaplus`,
      requirementsLoyalty: `${r}/fr/infos-consommateur/carte-gammaplus`
    }
  },
  karwei: {
    nl: {
      loyaltyProgram: `${r}/clubkarwei`,
      requirementsLoyalty: `${r}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    },
    karwei_nl: {
      loyaltyProgram: `${r}/clubkarwei`,
      requirementsLoyalty: `${r}/klantenservice/voorwaarden-veiligheid/algemene-voorwaarden/club-karwei`
    }
  }
})[t][s];
function ae(t) {
  const s = Je(`${t}/api`, {
    withCredentials: !0
  });
  return {
    getAccountState(r) {
      return s.get(
        `/account/?email=${encodeURIComponent(r)}`
      );
    },
    resendVerification(r) {
      return s.post(
        "/signup/resendsignupverification",
        r
      );
    },
    signUp(r) {
      return s.post("/signup", r);
    },
    verify(r, l, i) {
      return s.post(`/verify/${r}?hash=${l}`, {
        hash: l,
        customerId: i
      });
    },
    passwordReset(r) {
      return s.post("/password-reset", r);
    },
    verifyPasswordReset(r) {
      return s.post("/verify/password-reset", r);
    }
  };
}
class x extends Error {
  constructor(s) {
    super(s), this.name = "TranslatedError";
  }
}
const lt = "account";
function Fe() {
  const { t } = C(["register", "errors"]), { MYACCOUNT_URL: s } = H(), r = re(
    () => ae(s),
    [s]
  ), [l, i] = E(void 0), m = W(async () => l ? r.getAccountState(l).catch((a) => {
    throw S({
      type: "login_email_check_error",
      data: {
        error: (a == null ? void 0 : a.message) || ""
      }
    }), new x(
      `${t("errors:somethingWentWrong")} ${t(
        "errors:ContactCustomerSupport"
      )}`
    );
  }) : null, [l, r, t]), { data: o, isFetching: n, refetch: c, error: u } = Qe({
    queryKey: [lt, l],
    queryFn: m,
    throwOnError: !1
  });
  return {
    accountState: o,
    isFetching: n,
    error: u,
    refetch: c,
    getAccountState: (a) => {
      i(a);
    }
  };
}
const Ee = (t) => {
  switch (t) {
    case "nl_BE":
    case "nl-BE":
      return "nl_BE";
    case "fr_BE":
    case "fr-BE":
      return "fr_BE";
    default:
      return "nl";
  }
};
function ct({ onSuccess: t }) {
  const { MYACCOUNT_URL: s } = H(), { t: r, i18n: l } = C(["register", "errors"]), i = re(
    () => ae(s),
    [s]
  ), m = W(() => {
    te.addAction("signup");
  }, []);
  function o({ password: n, email: c, optIn: u, passwordless: a }) {
    return i.signUp({
      email: c,
      password: n,
      locale: Ee(l.language),
      optIn: u,
      verificationMethod: "CODE",
      passwordless: a
    }).catch((f) => {
      var d, g, _;
      if (he(f) && ((g = (d = f.response) == null ? void 0 : d.data) != null && g.status)) {
        const k = ((_ = f.response) == null ? void 0 : _.data).status;
        if (k === "UserSignUpInvalidEmail")
          throw new x(r("register:invalidEmail"));
        if (k === "UserSignUpPasswordInvalid")
          throw new x(r("register:invalidSignupPassword"));
        if (k === "UserSignUpAlreadyExists")
          throw new x(r("register:userAlreadyExists"));
      }
      throw me.error(
        a ? "Passwordless signup" : "Signup",
        {
          details: "Failed"
        },
        f
      ), new x(
        `${r("errors:somethingWentWrong")} ${r(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return ge({
    mutationFn: o,
    throwOnError: !1,
    onSuccess: (n, c) => {
      m(), S({
        type: "sign_up_interaction",
        data: {
          user_selected_element: "Sign_up verification",
          user_message_value: `Commercial opt-in: ${c.optIn}`,
          user_selected_value: `Verification method: ${c.passwordless ? "passwordless" : "email"}`
        }
      }), t(n, c);
    },
    onError: (n) => {
      S({
        type: "sign_up_error",
        data: {
          error: n == null ? void 0 : n.message
        }
      });
    }
  });
}
function dt({
  isPasswordless: t
}) {
  const { t: s } = C(["register"]);
  return I.object({
    email: I.string().min(1, s("register:emailRequired")).email(s("register:emailInvalid")),
    ...t ? {} : {
      password: I.string().min(8, s("register:minPasswordLength")).max(50, s("register:maxPasswordLength"))
    },
    requirements: I.literal(!0, {
      errorMap: () => ({ message: s("register:requirementsError") })
    }),
    optIn: I.boolean()
  });
}
function ut({ setShowLoyaltyBenefits: t }) {
  var Y, oe;
  const { t: s, i18n: r } = C([
    "common",
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), [l] = je(), i = l.get("method") === "passwordless", { signUpRequestBody: m, setSignUpRequestBody: o, setFlow: n } = ie(), c = dt({ isPasswordless: i }), {
    mutate: u,
    error: a,
    isPending: f
  } = ct({
    onSuccess: (w, b) => {
      o((p) => ({ ...p, ...b })), n({
        flow: R.VERIFY,
        verificationData: {
          customerId: w.customerId,
          email: b.email,
          hashedId: w.hashedId
        }
      }), t == null || t(!0), i && me.info("Passwordless signup", {
        details: "Successful"
      });
    }
  }), [d, g] = E(null), { brand: _, SHOPFRONT_URL: P } = H(), { getAccountState: k, accountState: v, isFetching: M } = Fe(), [U, G] = E(null), F = Ue(_, r.language, P), J = (F == null ? void 0 : F.requirementsLoyalty) ?? "/", N = (F == null ? void 0 : F.loyaltyProgram) ?? "/", {
    email: $,
    password: O,
    optIn: le
  } = m, V = ue({
    defaultValues: {
      email: $,
      password: O,
      requirements: !1,
      optIn: le
    },
    resolver: de(c)
  }), y = V.watch("password"), B = re(
    () => Le(y),
    [y]
  ), ne = Pe(B);
  L(() => {
    a != null && a.message && G(a.message);
  }, [a]);
  const D = V.handleSubmit(
    (w) => {
      k(w.email), g({
        ...w,
        locale: "nl",
        // FIXME: get locale from somewhere?
        verificationMethod: "CODE",
        password: i ? window.crypto.randomUUID() : w.password,
        passwordless: i
      });
    },
    (w) => {
      for (const { message: b } of Object.values(w))
        S({
          type: "sign_up_error",
          data: {
            error: b
          }
        });
    }
  );
  return L(() => {
    v && !M && (!v.hasAccount && d ? u({
      email: d.email,
      password: d.password,
      optIn: d.optIn,
      passwordless: d.passwordless
    }) : G(s("register:userAlreadyExists")));
  }, [
    v,
    M,
    s,
    d,
    u,
    o
  ]), L(() => {
    S({
      type: "sign_up_interaction",
      data: {
        user_selected_element: "Sign-up pop-up form"
      }
    });
  }, []), /* @__PURE__ */ h(ee, { children: [
    /* @__PURE__ */ e("h1", { className: "mb-4 text-200 font-bold", children: s("login-form:buttonLabelNewAccount") }),
    /* @__PURE__ */ h(
      "form",
      {
        noValidate: !0,
        onSubmit: (w) => (w.stopPropagation(), D(w)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
            ce,
            {
              type: "email",
              label: s("common:EmailAddress"),
              error: (Y = V.formState.errors.email) == null ? void 0 : Y.message,
              autoCapitalize: "off",
              autoComplete: "email",
              disabled: !0,
              icon: /* @__PURE__ */ e(
                "button",
                {
                  className: q(
                    "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                    "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                  ),
                  onClick: () => {
                    S({
                      type: "login_interaction",
                      data: {
                        user_selected_element: "Sign-up pop-up change e-mail link click"
                      }
                    }), n({ flow: R.LOGIN });
                  },
                  type: "button",
                  children: /* @__PURE__ */ e(pe, {})
                }
              ),
              ...V.register("email")
            }
          ) }) }) }),
          !i && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e(
            Ne,
            {
              autoComplete: "new-password",
              autoFocus: !0,
              label: s("login-form:Password"),
              error: (oe = V.formState.errors.password) == null ? void 0 : oe.message,
              passwordStrength: B,
              description: ne,
              ...V.register("password")
            }
          ) }) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            fe,
            {
              control: V.control,
              name: "requirements",
              defaultValue: !1,
              render: ({ field: w, fieldState: b }) => {
                var p;
                return /* @__PURE__ */ e(
                  be,
                  {
                    ref: w.ref,
                    name: w.name,
                    checked: w.value,
                    "data-testid": "requirements-checkbox",
                    onCheckedChange: (j) => {
                      j !== "indeterminate" && w.onChange(j);
                    },
                    error: (p = b.error) == null ? void 0 : p.message,
                    label: /* @__PURE__ */ e(
                      ve,
                      {
                        ns: "register",
                        i18nKey: "requirements",
                        defaultValue: "Met het aanmaken van een account, ga ik akkoord met de <terms-link>voorwaarden</terms-link> en profiteer ik van alle gratis voordelen van de <loyalty-link>{{loyaltyCardName}}</loyalty-link>",
                        components: {
                          "terms-link": /* @__PURE__ */ e(
                            "a",
                            {
                              target: "_blank",
                              href: J,
                              rel: "noreferrer",
                              className: "underline",
                              children: "voorwaarden"
                            }
                          ),
                          "loyalty-link": /* @__PURE__ */ e(
                            "a",
                            {
                              target: "_blank",
                              href: N,
                              rel: "noreferrer",
                              className: "underline",
                              children: "pas"
                            }
                          )
                        }
                      }
                    )
                  }
                );
              }
            }
          ) }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            fe,
            {
              control: V.control,
              name: "optIn",
              defaultValue: !1,
              render: ({ field: w, fieldState: b }) => {
                var p;
                return /* @__PURE__ */ e(
                  be,
                  {
                    ref: w.ref,
                    name: w.name,
                    checked: w.value,
                    onCheckedChange: (j) => {
                      j !== "indeterminate" && w.onChange(j);
                    },
                    error: (p = b.error) == null ? void 0 : p.message,
                    label: s("register:optinNewsletter")
                  }
                );
              }
            }
          ) }),
          U && /* @__PURE__ */ e(_e, { variant: "error", icon: /* @__PURE__ */ e(Ce, {}), children: U }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            Q,
            {
              loading: M || f,
              className: "w-full text-center",
              type: "submit",
              children: s("register:description")
            }
          ) }),
          /* @__PURE__ */ h("div", { children: [
            /* @__PURE__ */ e("b", { children: s("register:alreadyHaveAccount") }),
            /* @__PURE__ */ e("br", {}),
            /* @__PURE__ */ e(
              "button",
              {
                className: q(
                  "underline gamma:rounded-md",
                  "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                ),
                onClick: () => n({ flow: R.LOGIN }),
                children: s("register:loginUrl")
              }
            )
          ] })
        ]
      }
    )
  ] });
}
function Te() {
  const { MYACCOUNT_URL: t } = H(), { i18n: s, t: r } = C(["register"]), l = re(
    () => ae(t),
    [t]
  ), i = W((o) => {
    te.addAction("resend_verification_failed", {
      errorDetails: o == null ? void 0 : o.message
    });
  }, []);
  function m({
    email: o,
    sms: n,
    phoneNumber: c
  }) {
    return l.resendVerification({
      email: o,
      locale: Ee(s.language),
      sms: n,
      phoneNumber: c,
      verificationMethod: "CODE"
    }).catch((u) => {
      var a;
      if (S({
        type: "resend_verification_error",
        data: {
          user_message_type: (u == null ? void 0 : u.message) || "",
          verificationMethod: n ? "sms" : "email"
        }
      }), he(u) && ((a = u.response) == null ? void 0 : a.data).status === "ResendInvalidEmail")
        throw new x(
          r(n ? "register:phoneNumberAlreadyExists" : "register:userAlreadyExists")
        );
    }).finally(() => {
      S({
        type: "resend_verification",
        data: {
          verificationMethod: n ? "sms" : "email"
        }
      });
    });
  }
  return ge({
    mutationFn: m,
    throwOnError: !1,
    onError: (o) => {
      i(o);
    }
  });
}
function mt({
  email: t,
  goToStep: s,
  goToFlow: r
}) {
  const { t: l } = C("login-form"), { mutate: i, isPending: m, isSuccess: o, data: n } = Te();
  L(() => {
    o && n && r({
      flow: R.VERIFY,
      verificationData: {
        customerId: n.customerId,
        email: n.email,
        hashedId: n.hashedId
      }
    });
  }, [o, s, r, n]);
  const c = () => {
    S({ type: "sign_up_change_email" }), s(Z.LOGIN);
  };
  return /* @__PURE__ */ h("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ h("div", { className: "grid gap-4", children: [
      /* @__PURE__ */ e("p", { children: l("bodyNoVerfication") }),
      /* @__PURE__ */ e("p", { children: l("bodyNoVerification2") }),
      /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e("strong", { children: t }) }) })
    ] }),
    /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
      Q,
      {
        className: "w-full",
        loading: m,
        onClick: () => i({ email: t }),
        children: l("buttonLabelResendEmail")
      }
    ) }),
    /* @__PURE__ */ e("div", { className: "text-center", children: /* @__PURE__ */ e(
      "button",
      {
        className: q(
          "underline gamma:rounded-md",
          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
        ),
        onClick: c,
        children: l("buttonLabelOtherEmail")
      }
    ) })
  ] });
}
function gt({ email: t }) {
  const { t: s } = C("login-form");
  return /* @__PURE__ */ h("div", { className: "grid gap-4", children: [
    /* @__PURE__ */ e("p", { children: s("bodyConfirmEmailSent") }),
    /* @__PURE__ */ e("p", { className: "text-center", children: /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e("strong", { children: t }) }) }),
    /* @__PURE__ */ e("p", { children: s("bodyEmailSteps") })
  ] });
}
function ft({ onSubmit: t, isLoading: s }) {
  const { t: r } = C("login-form"), { brand: l, country: i } = H();
  let m = [];
  l === "gamma" ? m = i === "be" || i === "BE" ? ["2613", "2614", "2617", "2618", "2629"] : ["2611", "2615", "2616"] : l === "karwei" && (m = ["2612", "2619"]);
  const n = I.object({
    loyaltyCardNumber: I.string().min(1, r("cardNumberRequired")).length(13, r("LoyaltyCardNumberInvalid")).refine((a) => m.some((f) => a.startsWith(f)), {
      message: r("LoyaltyCardNumberInvalid")
    }).refine(
      (a) => a.split("").reduce(
        (d, g, _) => _ % 2 === 0 ? d + Number(g) : d + 3 * Number(g),
        0
      ) % 10 === 0,
      {
        message: r("LoyaltyCardNumberInvalid")
      }
    )
  }), c = ue({
    mode: "onBlur",
    defaultValues: {
      loyaltyCardNumber: ""
    },
    resolver: de(n)
  }), u = c.handleSubmit((a) => {
    t(a.loyaltyCardNumber);
  });
  return /* @__PURE__ */ h(ee, { children: [
    /* @__PURE__ */ h("div", { className: "relative flex p-4 items-center", children: [
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" }),
      /* @__PURE__ */ e("span", { className: "flex-shrink mx-4 text-ignew-neutral-500 uppercase", children: r("or") }),
      /* @__PURE__ */ e("div", { className: "flex-grow border-t border-ignew-neutral-500" })
    ] }),
    /* @__PURE__ */ h(
      "form",
      {
        noValidate: !0,
        onSubmit: (a) => (a.stopPropagation(), u(a)),
        className: "flex flex-col gap-4",
        children: [
          /* @__PURE__ */ e(
            fe,
            {
              rules: {
                required: r("cardNumberRequired"),
                pattern: {
                  value: /^\d+$/,
                  message: r("LoyaltyCardNumberInvalid")
                }
              },
              render: ({ field: { onChange: a, ...f } }) => {
                var d;
                return /* @__PURE__ */ e(
                  ce,
                  {
                    onChange: (g) => {
                      (g.target.value === "" || /^[0-9\b]+$/.test(g.target.value)) && a(g.target.value);
                    },
                    type: "text",
                    label: r("enterLoyaltyCardNumber"),
                    maxLength: 13,
                    inputMode: "numeric",
                    error: (d = c.formState.errors.loyaltyCardNumber) == null ? void 0 : d.message,
                    ...f
                  }
                );
              },
              control: c.control,
              name: "loyaltyCardNumber",
              defaultValue: ""
            }
          ),
          /* @__PURE__ */ e(Q, { type: "submit", variant: "secondary", loading: s, children: r("RegisterLoyaltyCardNumber") })
        ]
      }
    )
  ] });
}
function pt({
  showTermsAndConditions: t,
  showPasswordField: s
}) {
  const { t: r } = C(["ig-header", "login"]);
  return I.object({
    email: I.string().min(1, r("ig-header:loginEmailRequired")).email(r("ig-header:loginEmailInvalid")),
    password: s ? I.string().min(1, r("ig-header:loginRequired")) : I.string(),
    requirements: t ? I.literal(!0, {
      errorMap: () => ({ message: r("login:requirementsError") })
    }) : I.any()
  });
}
function ht({
  isLoading: t,
  onLogin: s,
  errorMessage: r,
  setShowLoyaltyBenefits: l,
  loyaltyCardLogin: i,
  initLocation: m,
  loginData: o
}) {
  var Ie;
  const { t: n, i18n: c } = C([
    "ig-header",
    "login",
    "urls",
    "login-form",
    "register",
    "common"
  ]), u = He(), { signUpRequestBody: a, setSignUpRequestBody: f, setFlow: d, loginAfterSignUp: g } = ie(), { brand: _, SHOPFRONT_URL: P } = H(), k = Ye(), [v, M] = E(
    !!(o != null && o.email)
  ), [U, G] = E(void 0), [F, J] = E(!1), [N, $] = E(Z.LOGIN), O = Ue(_, c.language, P), le = (O == null ? void 0 : O.requirementsLoyalty) ?? "/", V = (O == null ? void 0 : O.loyaltyProgram) ?? "/", {
    accountState: y,
    getAccountState: B,
    refetch: ne,
    error: D,
    isFetching: Y
  } = Fe(), oe = g ? a.email : ((Ie = u.state) == null ? void 0 : Ie.email) || (o == null ? void 0 : o.email), w = pt({
    showTermsAndConditions: F,
    showPasswordField: v
  }), b = ue({
    defaultValues: {
      email: oe || "",
      password: "",
      requirements: !1
    },
    resolver: de(w)
  }), p = () => {
    U && (b.setValue("email", U.full), G(void 0), S({ type: "email_suggestion_click" }));
  }, j = b.watch("email"), $e = async (A) => {
    const X = await b.trigger("email");
    A && X && (A === a.email && y ? ne() : B(A), f((z) => ({
      ...z,
      email: A,
      password: ""
    })), S({ type: "login_email_check" }));
  }, Re = W(
    (A) => {
      A != null && A.length && (y != null && y.customerId) && (S({
        type: "login_interaction",
        data: {
          user_selected_element: "Login by code"
        }
      }), d({
        flow: R.VERIFY,
        verificationData: {
          email: A,
          customerId: y.customerId,
          loginViaCode: !0,
          hashedId: y.hashedId || ""
        }
      }));
    },
    [y, d]
  );
  L(() => {
    o && !y && !Y && B(o.email);
  }, [o, y, Y, B]), L(() => {
    if (y && !Y) {
      const { hasAccount: A, hasLoyaltyCard: X, verified: z } = y;
      A ? z ? o != null && o.forcePasswordFlow || et ? (M(!0), b.setFocus("password")) : Re(a.email) : $(Z.RESEND_VERIFICATION) : d({ flow: R.SIGN_UP }), A && !X && (J(!0), typeof l < "u" && l(!0));
    }
  }, [
    y,
    Y,
    l,
    k,
    d,
    a.email
  ]), L(() => {
    g && typeof l < "u" && l(!0);
  }, [g, l]), L(() => {
    N === Z.LOGIN && S({
      type: "login_interaction",
      data: {
        user_selected_element: v ? "Login pop-up" : "Login / Signup email check pop-up",
        ...!v && { user_selected_value: m }
      }
    });
  }, [N, v, m]);
  const De = b.handleSubmit((A) => {
    const { email: X, password: z } = A;
    y != null && y.customerId && z && s({
      body: {
        customerId: y.customerId,
        email: X,
        password: z,
        hashedId: y.hashedId || ""
      }
    });
  });
  return /* @__PURE__ */ e("div", { "data-testid": "login-form", children: ((A) => {
    var X, z;
    switch (A) {
      case Z.LOGIN:
        return /* @__PURE__ */ h(ee, { children: [
          /* @__PURE__ */ h(
            "form",
            {
              noValidate: !0,
              onSubmit: (T) => {
                T.stopPropagation(), De(T);
              },
              className: "flex flex-col gap-4",
              children: [
                /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: n(v ? "login-form:loginTitle" : "login-form:titleLogInOrSignUp") }),
                /* @__PURE__ */ e("p", { children: v ? /* @__PURE__ */ h(ee, { children: [
                  n("login-form:bodyWelcomeBack"),
                  /* @__PURE__ */ e("br", {}),
                  n("login-form:bodyPasswordRequest")
                ] }) : n("login-form:bodyUnsureAccount") }),
                /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e("div", { className: "relative", children: /* @__PURE__ */ e(
                  ce,
                  {
                    type: "email",
                    label: n("common:EmailAddress"),
                    error: (X = b.formState.errors.email) == null ? void 0 : X.message,
                    autoCapitalize: "off",
                    autoComplete: "email",
                    disabled: v,
                    "data-testid": "login-email-input",
                    description: U ? /* @__PURE__ */ h("span", { role: "alert", children: [
                      n("common:DidYouMean"),
                      " ",
                      /* @__PURE__ */ h(
                        "button",
                        {
                          className: q(
                            "underline",
                            "gamma:rounded-md outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                          ),
                          onClick: p,
                          children: [
                            "@",
                            U.domain
                          ]
                        }
                      ),
                      "?"
                    ] }) : void 0,
                    icon: v && /* @__PURE__ */ e(
                      "button",
                      {
                        className: q(
                          "bg-transparent border-none p-0 cursor-pointer text-2xl gamma:rounded-md",
                          "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                        ),
                        "data-testid": "login-change-email",
                        onClick: (T) => {
                          T.preventDefault(), S({
                            type: "login_interaction",
                            data: {
                              user_selected_element: "Login pop-up change e-mail link click"
                            }
                          }), b.setValue("email", ""), M(!1);
                        },
                        type: "button",
                        children: /* @__PURE__ */ e(pe, {})
                      }
                    ),
                    ...b.register("email", {
                      onChange(T) {
                        Ze(
                          T.target.value,
                          G
                        );
                      }
                    })
                  }
                ) }) }) }),
                /* @__PURE__ */ e(
                  "div",
                  {
                    className: v ? "" : "h-0 overflow-hidden absolute",
                    children: /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e(
                      Ne,
                      {
                        ...!v && {
                          tabIndex: -1,
                          "aria-hidden": "true"
                        },
                        autoComplete: v ? "current-password" : "off",
                        label: n("ig-header:loginPasswordLabel"),
                        error: ((z = b.formState.errors.password) == null ? void 0 : z.message) || r,
                        ...b.register("password")
                      }
                    ) })
                  }
                ),
                F && /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
                  fe,
                  {
                    control: b.control,
                    name: "requirements",
                    defaultValue: !1,
                    render: ({ field: T, fieldState: We }) => {
                      var ke;
                      return /* @__PURE__ */ e(
                        be,
                        {
                          ref: T.ref,
                          name: T.name,
                          checked: T.value,
                          onCheckedChange: (Ae) => {
                            Ae !== "indeterminate" && T.onChange(Ae);
                          },
                          error: (ke = We.error) == null ? void 0 : ke.message,
                          label: /* @__PURE__ */ h(ve, { ns: "login", i18nKey: "requirements", children: [
                            "Met het aanmaken van een account, ga ik akkoord met de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: le,
                                rel: "noreferrer",
                                children: "voorwaarden"
                              }
                            ),
                            "en profiteer ik van alle gratis voordelen van de",
                            /* @__PURE__ */ e(
                              "a",
                              {
                                target: "_blank",
                                href: V,
                                rel: "noreferrer",
                                children: "pas"
                              }
                            )
                          ] })
                        }
                      );
                    }
                  }
                ) }),
                (D == null ? void 0 : D.message) && /* @__PURE__ */ e(_e, { variant: "error", icon: /* @__PURE__ */ e(Ce, {}), children: D.message }),
                /* @__PURE__ */ e("div", { children: v ? /* @__PURE__ */ e(
                  Q,
                  {
                    loading: t,
                    className: "w-full text-center",
                    "data-testid": "login-submit",
                    type: "submit",
                    children: n("ig-header:Login")
                  }
                ) : /* @__PURE__ */ e(
                  Q,
                  {
                    loading: Y,
                    className: "w-full text-center",
                    onClick: () => $e(j),
                    "data-testid": "login-next",
                    children: n("login-form:buttonlabel")
                  }
                ) }),
                v && y && /* @__PURE__ */ h("div", { className: "text-center", children: [
                  /* @__PURE__ */ e("div", { className: "mb-4", children: /* @__PURE__ */ e(
                    "button",
                    {
                      className: q(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: (T) => {
                        T.preventDefault(), S({
                          type: "login_interaction",
                          data: {
                            user_selected_element: "Login pop-up password reset link click"
                          }
                        }), d({
                          flow: R.FORGOT_PASSWORD,
                          forgotPasswordData: {
                            email: b.getValues().email,
                            customerId: y.customerId,
                            hashedId: y.hashedId || ""
                          }
                        });
                      },
                      children: n("ig-header:loginForgotPassword")
                    }
                  ) }),
                  /* @__PURE__ */ e(
                    "button",
                    {
                      className: q(
                        "underline gamma:rounded-md",
                        "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
                      ),
                      onClick: () => {
                        Re(b.getValues().email);
                      },
                      children: n("login-form:loginWithCodeLink")
                    }
                  )
                ] })
              ]
            }
          ),
          typeof i < "u" && /* @__PURE__ */ e(
            ft,
            {
              onSubmit: i,
              isLoading: t
            }
          )
        ] });
      case Z.RESEND_VERIFICATION:
        return /* @__PURE__ */ h(ee, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: n("login-form:loginTitle") }),
          /* @__PURE__ */ e(
            mt,
            {
              email: a.email,
              goToStep: $,
              goToFlow: d
            }
          )
        ] });
      case Z.RESEND_SUCCESS:
        return /* @__PURE__ */ h(ee, { children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: n("register:titleIsThatYou") }),
          /* @__PURE__ */ e(gt, { email: a.email })
        ] });
      default:
        return null;
    }
  })(N) });
}
function wt() {
  const { MYACCOUNT_URL: t } = H(), { setFlow: s, setSignUpResponse: r, setVerificationError: l } = ie(), { t: i } = C(["register", "errors", "login"]), m = W(() => {
    te.addAction("verified_code");
  }, []), o = W((u) => {
    te.addAction("verified_code_failed", {
      errorDetails: u == null ? void 0 : u.message
    });
  }, []), n = re(
    () => ae(t),
    [t]
  );
  function c({ verificationCode: u, customerId: a }) {
    return n.verify("signup", `${a}_${u}`, a).catch((f) => {
      var d;
      if (he(f)) {
        const g = (d = f.response) == null ? void 0 : d.data;
        if (g.status === "VerifySignUpInvalidToken")
          if (g.httpStatus === 410)
            r(void 0), l(i("register:tooManyWrongAttempts")), s({ flow: R.SIGN_UP });
          else
            throw new x(i("register:errorNotValidCode"));
      }
      throw me.error("Verify code form failed", {}, f), (f == null ? void 0 : f.response.status) === 429 ? new x(i("errors:tooManyVerificationAttempts")) : new x(
        `${i("errors:somethingWentWrong")} ${i(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return ge({
    mutationFn: c,
    throwOnError: !1,
    onSuccess: () => {
      S({
        type: "verify_email"
      }), m();
    },
    onError: (u) => {
      S({
        type: "verify_email_error",
        data: {
          error: u == null ? void 0 : u.message
        }
      }), o(u);
    }
  });
}
function yt() {
  const [t, s] = E(!1), r = W(async (l) => {
    s(!0);
    try {
      return await tt({
        username: l,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP"
        }
      });
    } catch {
      return null;
    } finally {
      s(!1);
    }
  }, []);
  return {
    isPending: t,
    requestVerificationCode: r
  };
}
const qe = () => {
  const { t } = C(["register"]);
  return ue({
    defaultValues: {
      code: ""
    },
    resolver: de(
      I.object({
        code: I.string({ required_error: t("register:errorNotValidCode") }).min(se, t("register:errorNotValidCode")).regex(/^\d+$/, t("register:errorNotValidCode"))
      })
    ),
    mode: "onTouched",
    resetOptions: {
      keepDirtyValues: !0
    }
  });
};
function bt({
  navigateToLogin: t = !1,
  email: s,
  customerId: r,
  hashedId: l
}) {
  const { setFlow: i, setLoginAfterSignUp: m } = ie(), { t: o } = C(["verification", "common", "urls"]);
  return L(() => {
    we.success(o("verification:signupVerificationSuccessDescription"), {
      icon: Se
    }), i({ flow: R.LOGIN }), m(!0), S({
      type: "sign_up_activation",
      data: { kdbid: r, kdbid_hashed: l }
    });
  }, [o, i, m, r, l]), t ? /* @__PURE__ */ e(
    ze,
    {
      to: { pathname: o("urls:/login"), search: "type=new" },
      state: { email: s },
      replace: !0
    }
  ) : null;
}
function vt({
  verificationData: t,
  navigateToLogin: s = !1,
  onLogin: r,
  confirmSignInError: l
}) {
  var b;
  const { customerId: i, hashedId: m, email: o, loginViaCode: n } = t, { t: c } = C(["register", "common", "login-form"]), [u, a] = E(null), [f, d] = E(!1), [g, _] = E(!1), { setFlow: P, signUpRequestBody: k, setVerificationError: v } = ie(), M = ye(null), U = qe(), {
    register: G,
    setValue: F,
    formState: { errors: J }
  } = U, {
    isPending: N,
    requestVerificationCode: $
  } = yt(), O = ye(r), { mutate: le, error: V } = Te(), {
    mutate: y,
    isSuccess: B,
    isError: ne,
    error: D
  } = wt();
  L(() => {
    var p;
    (p = document.getElementById("code")) == null || p.focus();
  }, [N]), L(() => {
    n && $(i);
  }, [n, i, $]), L(() => {
    ne && _(!1);
  }, [ne]), L(() => {
    var p;
    B && O.current && ((p = k.password) != null && p.length) ? (S({
      type: "sign_up_activation",
      data: { kdbid: i, kdbid_hashed: m }
    }), O.current({
      body: {
        email: o,
        customerId: i,
        password: k.password,
        hashedId: m
      },
      disableAdobeEvent: !0
    })) : _(!1);
  }, [B, O, k, i, o, m]), L(() => {
    const p = (D == null ? void 0 : D.message) || (V == null ? void 0 : V.message);
    p && a(p);
  }, [V, D]);
  const Y = (p) => {
    var j;
    p.preventDefault(), (j = M.current) == null || j.reset(), a(null), t.loginViaCode ? $(i) : le({
      email: o
    }), we.success(c("register:successMessageResendCode"), { icon: Se }), d(!0), setTimeout(() => {
      d(!1);
    }, 3e4);
  }, oe = (p) => {
    S({
      type: "login_interaction",
      data: {
        user_selected_element: "Login by password"
      }
    }), P({
      flow: R.LOGIN,
      loginData: {
        email: p,
        // If a user has no password it would not show the password input.
        // This forces the input to show up.
        forcePasswordFlow: !0
      }
    });
  };
  L(() => {
    l && (_(!1), a(l));
  }, [l, u, c, P]);
  const w = U.handleSubmit((p) => {
    v(void 0), i && !n && (a(null), _(!0), y({ verificationCode: p.code, customerId: i })), n && O.current && (a(null), _(!0), O.current({
      body: {
        email: o,
        customerId: i,
        verificationCode: p.code,
        hashedId: m
      }
    }));
  });
  return B && !g ? /* @__PURE__ */ e(
    bt,
    {
      navigateToLogin: s,
      email: o,
      hashedId: m,
      customerId: i
    }
  ) : /* @__PURE__ */ h("div", { className: "flex flex-col gap-4", children: [
    /* @__PURE__ */ e("h4", { className: "text-200 font-bold", children: c(n ? "login-form:loginCodeSentTitle" : "register:titleIsThatYou") }),
    /* @__PURE__ */ e("div", { children: c(n ? "login-form:loginCodeSentDescription" : "register:bodyCodeSentTo") }),
    /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e(
      ce,
      {
        label: "",
        value: o,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: q(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => {
              P({ flow: R.LOGIN }), S({ type: "sign_up_change_email" });
            },
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(pe, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ e("p", { children: c(n ? "login-form:codeValidity" : "register:bodyFillCode") }),
    /* @__PURE__ */ h(
      "form",
      {
        className: "flex flex-col gap-4",
        noValidate: !0,
        onSubmit: w,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            Ve,
            {
              label: c("login-form:verifyNewPasswordCodeLabel", {
                length: se
              }),
              disabled: N,
              description: c("login-form:verifyNewPasswordCodeDescription"),
              error: ((b = J.code) == null ? void 0 : b.message) || u,
              ref: M,
              onChange: (p) => {
                F("code", p), p.length === se && w();
              }
            }
          ),
          /* @__PURE__ */ e("input", { type: "hidden", ...G("code") }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
            Q,
            {
              type: "submit",
              className: "w-full",
              loading: g || N,
              children: c(g ? "register:isVerifying" : "register:confirm")
            }
          ) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: q(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: f,
              onClick: (p) => Y(p),
              children: c("register:buttonLabelResendCode")
            }
          ) }),
          n && /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              type: "button",
              className: q(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              onClick: () => {
                oe(o);
              },
              children: c("login-form:loginPasswordLink")
            }
          ) })
        ]
      }
    )
  ] });
}
function Me() {
  const { MYACCOUNT_URL: t } = H(), { t: s } = C(["register", "errors"]), r = re(
    () => ae(t),
    [t]
  ), l = W(() => {
    te.addAction("request_new_password");
  }, []), i = W(
    (o) => {
      te.addAction("request_new_password_failed", {
        reason: o
      });
    },
    []
  );
  function m(o) {
    return r.passwordReset({ email: o, verificationMethod: xe.CODE }).catch((n) => {
      throw me.error("Reset password request failed", {}, n), (n == null ? void 0 : n.response.status) === 429 ? new x(s("errors:tooManyVerificationAttempts")) : new x(
        `${s("errors:somethingWentWrong")} ${s(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return ge({
    mutationFn: m,
    onSuccess: () => {
      l();
    },
    onError: (o) => {
      i((o == null ? void 0 : o.message) || "");
    }
  });
}
function St({ email: t, onSuccess: s }) {
  var d;
  const { t: r } = C([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), l = I.object({
    password: I.string().min(8, r("register:minPasswordLength")).max(50, r("register:maxPasswordLength"))
  }), i = ue({
    defaultValues: {
      password: ""
    },
    resolver: de(l)
  }), m = i.watch("password"), o = re(
    () => Le(m),
    [m]
  ), n = Pe(o), { mutate: c, isPending: u, error: a } = Me(), f = i.handleSubmit((g) => {
    c(t, {
      onSuccess() {
        s(g.password);
      }
    });
  });
  return /* @__PURE__ */ h(
    "form",
    {
      noValidate: !0,
      onSubmit: (g) => {
        g.stopPropagation(), f(g);
      },
      className: "flex flex-col gap-4",
      children: [
        /* @__PURE__ */ h("div", { className: "grid gap-4", children: [
          /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: r("login-form:chooseNewPasswordTitle") }),
          /* @__PURE__ */ e("p", { children: r("login-form:chooseNewPasswordBody", {
            length: se
          }) })
        ] }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e(
          Ne,
          {
            autoFocus: !0,
            autoComplete: "new-password",
            label: r("login-form:Password"),
            error: (d = i.formState.errors.password) == null ? void 0 : d.message,
            passwordStrength: o,
            description: n,
            ...i.register("password")
          }
        ) }) }),
        (a == null ? void 0 : a.message) && /* @__PURE__ */ e(_e, { variant: "error", icon: /* @__PURE__ */ e(Ce, {}), children: a.message }),
        /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(
          Q,
          {
            disabled: u,
            loading: u,
            className: "w-full text-center",
            children: r("login-form:buttonlabel")
          }
        ) })
      ]
    }
  );
}
function Nt() {
  const { MYACCOUNT_URL: t } = H(), { t: s } = C(["register", "errors"]), r = re(
    () => ae(t),
    [t]
  ), l = W(() => {
    te.addAction("verified_new_password");
  }, []), i = W((o) => {
    te.addAction("verifiy_new_password_failed", {
      reason: o
    });
  }, []);
  function m(o) {
    return r.verifyPasswordReset(o).catch((n) => {
      var c;
      if (he(n)) {
        const u = (c = n.response) == null ? void 0 : c.data;
        if (u.status === "VerifyPasswordResetInvalidToken")
          throw u.httpStatus === 410 ? new x(s("register:tooManyWrongAttempts")) : new x(s("register:errorNotValidCode"));
      }
      throw me.error("Verify code form failed", {}, n), (n == null ? void 0 : n.response.status) === 429 ? new x(s("errors:tooManyVerificationAttempts")) : new x(
        `${s("errors:somethingWentWrong")} ${s(
          "errors:ContactCustomerSupport"
        )}`
      );
    });
  }
  return ge({
    mutationFn: m,
    throwOnError: !1,
    onSuccess: () => {
      S({ type: "login_change_password" }), l();
    },
    onError: (o) => {
      i((o == null ? void 0 : o.message) || "");
    }
  });
}
function Ct({
  email: t,
  password: s,
  customerId: r,
  onSuccess: l
}) {
  var J;
  const { t: i } = C([
    "register",
    "errors",
    "ig-form",
    "urls",
    "login-form"
  ]), [m, o] = E(null), n = ye(null), c = qe(), {
    register: u,
    setValue: a,
    formState: { errors: f }
  } = c, { setFlow: d } = ie(), {
    mutate: g,
    isPending: _,
    error: P
  } = Nt(), { mutate: k, error: v } = Me(), [M, U] = E(!1), G = c.handleSubmit((N) => {
    o(null), g(
      { newPassword: s, customerId: r, code: N.code },
      { onSuccess: l }
    );
  });
  L(() => {
    const N = (P == null ? void 0 : P.message) || (v == null ? void 0 : v.message);
    N && o(N);
  }, [v, P]);
  const F = (N) => {
    N.preventDefault(), k(t, {
      onSuccess() {
        var $;
        we.success(i("register:successMessageResendCode"), {
          icon: Se
        }), o(null), ($ = n.current) == null || $.reset();
      }
    }), U(!0), setTimeout(() => {
      U(!1);
    }, 3e4);
  };
  return /* @__PURE__ */ h(ee, { children: [
    /* @__PURE__ */ e("h4", { className: "mb-4 text-200 font-bold", children: i("login-form:verifyNewPasswordTitle") }),
    /* @__PURE__ */ e("div", { className: "mb-4", children: i("login-form:verifyNewPasswordBody", { length: se }) }),
    /* @__PURE__ */ e(K, { children: /* @__PURE__ */ e(
      ce,
      {
        label: "",
        value: t,
        disabled: !0,
        icon: /* @__PURE__ */ e(
          "button",
          {
            className: q(
              "underline bg-transparent gamma:rounded-md",
              "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
            ),
            onClick: () => d({ flow: R.LOGIN }),
            "data-testid": "change-email-button",
            type: "button",
            children: /* @__PURE__ */ e(pe, {})
          }
        )
      }
    ) }),
    /* @__PURE__ */ h(
      "form",
      {
        className: "flex flex-col gap-4 mt-4",
        noValidate: !0,
        onSubmit: G,
        method: "POST",
        children: [
          /* @__PURE__ */ e(
            Ve,
            {
              ref: n,
              label: i("login-form:verifyNewPasswordCodeLabel", {
                length: se
              }),
              error: ((J = f.code) == null ? void 0 : J.message) || m,
              disabled: _,
              description: i("login-form:verifyNewPasswordCodeDescription"),
              onChange: (N) => {
                a("code", N), N.length === se && G();
              }
            }
          ),
          /* @__PURE__ */ e("input", { type: "hidden", ...u("code") }),
          /* @__PURE__ */ e("div", { children: /* @__PURE__ */ e(Q, { className: "w-full", loading: _, children: i(_ ? "register:isVerifying" : "register:confirm") }) }),
          /* @__PURE__ */ e("div", { className: "mt-2 text-center", children: /* @__PURE__ */ e(
            "button",
            {
              className: q(
                "underline gamma:rounded-md",
                "outline-none ring-offset-1 focus-visible:ring-3 focus-visible:ring-ignew-functional-focus-400"
              ),
              disabled: M,
              onClick: (N) => F(N),
              children: i("register:buttonLabelResendCode")
            }
          ) })
        ]
      }
    )
  ] });
}
function _t({
  values: t,
  onLogin: s
}) {
  const [r, l] = E({
    step: "CHOOSE_PASSWORD",
    email: t.email
  }), { t: i } = C(["change-password"]);
  return /* @__PURE__ */ h(ee, { children: [
    r.step === "CHOOSE_PASSWORD" && /* @__PURE__ */ e(
      St,
      {
        email: r.email,
        onSuccess: (m) => {
          l({
            step: "VERIFY_REQUEST",
            email: t.email,
            password: m
          });
        }
      }
    ),
    r.step === "VERIFY_REQUEST" && /* @__PURE__ */ e(
      Ct,
      {
        customerId: t.customerId,
        email: r.email,
        password: r.password,
        onSuccess: () => {
          we.success(i("change-password:successfulSave")), s({
            body: {
              email: r.email,
              customerId: t.customerId,
              password: r.password,
              hashedId: t.hashedId
            },
            disableAdobeEvent: !0
          });
        }
      }
    )
  ] });
}
function er(t) {
  const [s, r] = E({ flow: R.LOGIN }), [l, i] = E(), [m, o] = E(void 0), { i18n: n } = C(), [c, u] = E(!1), [a, f] = E(
    {
      email: "",
      password: "",
      locale: Ee(n.language),
      optIn: !1,
      verificationMethod: "CODE",
      passwordless: !1
    }
  );
  return /* @__PURE__ */ e("div", { className: "px-4 py-6", children: /* @__PURE__ */ h(at, { value: {
    signUpRequestBody: a,
    setSignUpRequestBody: f,
    setFlow: r,
    loginAfterSignUp: c,
    setLoginAfterSignUp: u,
    signUpResponse: l,
    setSignUpResponse: i,
    setVerificationError: o,
    verificationError: m
  }, children: [
    s.flow === R.LOGIN && /* @__PURE__ */ e(ht, { ...t, loginData: s.loginData }),
    s.flow === R.SIGN_UP && /* @__PURE__ */ e(
      ut,
      {
        setShowLoyaltyBenefits: t.setShowLoyaltyBenefits
      }
    ),
    s.flow === R.VERIFY && /* @__PURE__ */ e(
      vt,
      {
        verificationData: s.verificationData,
        navigateToLogin: t.isMyAccount,
        confirmSignInError: t.errorMessage,
        onLogin: t.onLogin
      }
    ),
    s.flow === R.FORGOT_PASSWORD && /* @__PURE__ */ e(
      _t,
      {
        values: s.forgotPasswordData,
        onLogin: t.onLogin
      }
    )
  ] }) });
}
function tr({
  loyaltyCardNumber: t,
  close: s
}) {
  const { t: r, i18n: l } = C(["urls", "loyalty-benefits"]), { brand: i, MYACCOUNT_URL: m } = H(), o = Ke(), n = () => {
    s(), o(r("urls:/profile/loyaltycards"), {
      state: { showForm: !0 },
      replace: !0
    });
  }, c = `${m}/api/app/loyalty/cards/apple-wallet-pass`, u = nt() === ot.IOS, a = async (f) => {
    const d = f.currentTarget;
    if (!(d != null && d.href))
      try {
        const g = await Xe(), P = await (await fetch(c, {
          headers: {
            ...g,
            Accept: "application/vnd.apple.pkpass"
          }
        })).blob(), k = window.URL.createObjectURL(P);
        d.download = `${i}.pkpass`, d.href = k, d.click(), d.removeAttribute("href"), d.removeAttribute("download"), window.URL.revokeObjectURL(k);
      } catch {
        throw new Error("Failed to download loyalty card");
      }
  };
  return /* @__PURE__ */ h("div", { className: "p-0 md:p-4 grid grid-cols-2 gap-4", children: [
    /* @__PURE__ */ h("div", { className: "col-span-2", children: [
      /* @__PURE__ */ e("h3", { className: "mb-6 text-200/6 pr-6", children: r("loyalty-benefits:titleCongratsNewVdp") }),
      u && /* @__PURE__ */ e("div", { className: "mb-6 text-75/6", children: r("loyalty-benefits:welcomeAddWallet") }),
      /* @__PURE__ */ h("div", { className: "flex flex-row", children: [
        /* @__PURE__ */ e("div", { className: "w-6 h-6 mr-1", children: /* @__PURE__ */ e(st, { className: "gamma:text-brand primary karwei:text-ignew-neutral-1000" }) }),
        /* @__PURE__ */ e("p", { children: /* @__PURE__ */ h(ve, { t: r, i18nKey: "loyalty-benefits:welcomeBenefits", children: [
          /* @__PURE__ */ e("strong", { children: "250" }),
          " welkomstpunten t.w.v. €2,50 zijn toegevoegd aan je account"
        ] }) })
      ] })
    ] }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("div", { className: "w-full", children: /* @__PURE__ */ e(
      rt,
      {
        language: l.language,
        cardNumber: t
      }
    ) }) }),
    u && /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e("a", { onClick: a, className: "cursor-pointer", children: /* @__PURE__ */ e(
      "img",
      {
        src: l.language === "nl" ? "https://mijn.gamma.nl/static/images/apple_wallet_nl_full.svg" : "https://mijn.gamma.nl/static/images/apple_wallet_fr_full.svg",
        className: "w-full",
        alt: "Apple Wallet"
      }
    ) }) }),
    /* @__PURE__ */ e("div", { className: "col-span-2", children: /* @__PURE__ */ e(Q, { onClick: n, className: "w-full", children: r("loyalty-benefits:buttonAddPhysicalPass") }) })
  ] });
}
export {
  er as A,
  R as F,
  tr as L,
  gt as R,
  ut as S,
  vt as V,
  Oe as a,
  at as b,
  ht as c,
  ft as d,
  mt as e,
  bt as f,
  Ue as g,
  Fe as h,
  it as i,
  Te as j,
  ct as k,
  qe as l,
  wt as m,
  Z as n,
  xe as o,
  ie as u
};
